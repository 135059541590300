
module.exports =
  metaInfo:
    title: 'Уход за собой — экономим красиво'
    meta: [
      { name: 'description', content: 'Ухаживаем за внешностью и за семейным бюджетом: умная экономия на косметике, салонах красоты и фитнес-центрах без ущерба внешнему виду' }
      { name: 'keywords', content: 'экономия на красоте, экономить на красоте, экономить на косметике' }
      { name: 'human_title', content: 'Уход за собой — экономим красиво' }
      { name: 'human_subtitle', content: 'Ухаживаем за внешностью и за семейным бюджетом: умная экономия на косметике, салонах красоты и фитнес-центрах без ущерба внешнему виду.' }
    ]
